
@import url('https://fonts.googleapis.com/css2?family=Raleway');

:root {

  /* Backendin endpointit ja mapboxin avaimet löydät .env tiedostosta */

  /*Sovellusen paafontti*/
  --main-font: Raleway, sans-serif;

  /* Kuvat */

  /* !!! Käytä samoja kuvaformaatteja !!!*/

  /* Etusivun main logo (keskellä ylhäällä), korvaa src/assets/pictures/etusivulogo.png */
  /* Navbar oikean kulman logo/vaakuna, korvaa src/assets/pictures/kulmalogo.png */
  /* Etusivun kansikuva, korvaa src/assets/pictures/kansikuva.jpg */
  /* Latausruudun kuva, default kuva tapahtumille, korvaa src/assets/pictures/latausruutukuva.jpg */
  /* Tiedotteet/ajankohtaista taustakuva, korvaa src/assets/pictures/tiedotteetbg.jpg */

  /*-----------------------------------------------------------------*/

  /* Äpin taustaväri */
  --mainbackground-color: #fff;

  /* Etusivu */
  /* ------- */

  /* Päävalikko ja sivuvalikko */
  --main-color: #fae024;

  /* Toinen pääväri */
  --secondary-color: #8cd701;
  
  /* Tekstejen pääväri */
  --dark-color: #101010;
  
  /* Etusivun Tiedotteet ja Vastaukset taustaväri */
  --home-container-color: rgba(128,186,39, 0.3); 

  /*-----------------------------------------------------------------*/

  /* Tiedotteet Sivu */
  /* --------------- */

  /* Tiedotteet containerin taustaväri */
  --tiedotteet-tausta: white;
  
  /* Tiedotteet kortin taustaväri */
  --tiedotteet-card-tausta: #fff;

  /*-----------------------------------------------------------------*/

  /* Tapahtumat Sivu */
  /* --------------- */

  /* Tapahtumat alavalikon taustaväri */
  --tapahtumat-alavalikko-tausta: #80ba27;

  /* Tapahtumakortin päivämäärän tausta väri */
  --tapahtumatcard-date-tausta: #f4f1ba;

  /* Tapahtumakortin tausta */
  --tapahtumatcard-tausta: #fff;

  /*-----------------------------------------------------------------*/

  /* Paikat ja kohteet Sivu */
  /* ---------------------- */

  /* Sivu / filteröinti valikko "rightsidevalikko"*/

  /* Taustaväri */
  --rightsidevalikko-tausta: rgba(250,224,36,0.8);

  /* Tekstit */
  --rightsidevalikko-teksti: #101010;
  
  /* Switch on, off */
  --switch-on: #8cd701;
  --switch-off: rgb(227,53,73);

  /* Alavalikkokohdekortin taustaväri */
  --kohdekortin-tausta: rgba(128,186,39, 0.475);
  /* Kun valittu */
  --kohdekortin-tausta-selected: rgba(128,186,39, 0.95);

  /* Kohdekortin tekstin ja info iconin väri */
  --kohdekortin-tekstit: #fff;

}
