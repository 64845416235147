.navbar {
  background-color: rgb(13, 104, 139);
  height: 60px;
  top:0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  z-index: 4;
}

.menu-bars {
  margin-left: 0.1rem;
  margin-top: -0.3rem;
  font-size: 1.8rem;
  background: none;
}

.nav-menu {
  background-color: rgb(13, 104, 139);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  padding-top: 70px;
  left: -100%;
  transition: 850ms;
  z-index: 3;
  overflow-y:auto;
}
.nav-menu::-webkit-scrollbar {
  display: none;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 43px;
  z-index: 1;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 15px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 4px;
}

.nav-text a:hover {
  color: black;
  text-decoration: none;

}

.nav-menu-items {
  width: 120%;
  margin-left: -40px;
}

.navbar-toggle {
  background-color: rgb(13, 104, 139);;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.external-link{
  margin-top: 15px;
  color:  white;
  font-size:  15px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 31px;
  padding-bottom: 20px;

}

.external-link a{
  padding-left:  16px;
  color:  white;
  text-decoration: none;
}

.external-link a:hover {
  color: black;
  text-decoration: none;


}

.language-switch{
  display: flex;
  justify-content: center;
}
.language-switch button{
  border: 0px;
  padding: 3px 7px;
}
.language-switch button:disabled{
  background-color: rgb(29, 29, 29);
  color: rgb(106, 106, 106);
}
.language-switch button{
  background-color: rgb(227, 227, 227);
}
