.vikaheader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: rgb(13, 104, 139);
  margin-bottom: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* .myform{
  position: 'absolute';
  top: 150px;
} */

span {
  margin-left: 5px;
}

.mapboxgl-popup-content{
  /*margin-top:  20px;*/
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.mapboxgl-ctrl-icon {
  margin: 0px 0px 0px 0px;
}

.mapboxgl-popup{
  margin-top:  20px;
  max-width: 360px !important;
}